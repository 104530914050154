import { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './App.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);


//A comment

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
};


const supabaseUrl = "https://jphgstpvuaxkjoorniab.supabase.co"
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpwaGdzdHB2dWF4a2pvb3JuaWFiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTIwMjYyODEsImV4cCI6MjAwNzYwMjI4MX0.eL7yZwISuQzdWHgr5ebXEucizHCyivTY5XGuxE9pCB4"
const supabase = createClient(supabaseUrl, supabaseKey)

function App() {
  const [chartData, setChartData] = useState(null)
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // Fetch data from Supabase
    async function fetchData() {
      const { data, error } = await supabase.from('ltt_avg_every_ten').select('*').order('created_at', { ascending: true });

      if (error) {
        console.error('Error fetching data:', error);
        return;
      }

      // Assuming the table has 'timestamp' and 'subscribers' columns
      const labels = data.map(row => {
        const date = new Date(row.created_at);
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

        const formattedDate =
          monthNames[date.getMonth()] + ' ' +
          String(date.getDate()).padStart(2, '0') + ', ' +
          String(date.getHours()).padStart(2, '0') + ':' +
          String(date.getMinutes()).padStart(2, '0') + ':' +
          String(date.getSeconds()).padStart(2, '0');

        return formattedDate;
      });

      const subscribers = data.map(row => row.subscribers);


      setChartData({
        labels: labels,
        datasets: [{
          label: 'Subscribers',
          data: subscribers,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      });

      setTableData(data.slice(-100).reverse());
    }

    fetchData();
  }, []);

  return (
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8">
      <div class="mx-auto max-w-3xl prose lg:proxe-xl mb-10">
        <h1>
          LinusTechTips Floatplane Subscribers
        </h1>
        <p>
          2023-08-17: I updated the datasource so that it outputs the average of each ten minutes as there are now a LOT of rows. Note that this is still being checked every minute.
          <br />
          <br />
          2023-08-18: There's a few other sites available, they have various amount of data, some are better:<br />
          <ul>
            <li>
              <a href="http://fp-stats.buzz/">fp-stats.buzz, zoomable charts, data before the drama</a>
            </li>
            <li>
              <a href="https://floatplane.wtf/">floatplane.wtf, datapoints every 30 minutes</a>
            </li>
            <li>
              <a href="https://grafana.elizabeth.codes/public-dashboards/10023c4c479744e191760225708c0124?orgId=1&from=now-2d&to=now">grafana.elizabeth.codes</a>
            </li>
          </ul>
          <br />
          If you want access to the raw data, please email active.luck5291@fastmail.com
        </p>

        {chartData && <Line data={chartData} />}
        <div className="mt-6">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Time
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Subscribers
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {tableData.map((row) => (
                <tr key={row.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(row.created_at).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {row.subscribers}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


      </div>

    </div>
  );
}

export default App;
